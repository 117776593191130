import styled from 'styled-components';

export default styled.div`

  padding: 200px 0 200px;
  height: 100vh;
  .container {
    font-family: 'Open Sans',sans-serif;
    width: 900px;
    margin: 0 auto;
    padding: 0;
    left: calc(50% - (900px / 2));
    top: calc(50% - (260px / 2));
    position: absolute;
    #hero{
      margin-top: 10px;

      h1 {
        font-size: 45px;
        font-weight: 800;
        color: #fff;
      }
      p {
        font-size: 16px;
        line-height: 26px;
        padding: 45px 0 18px;
        margin-bottom: 20px;
        color: #fff;
        a {
          color: #fff;
          text-decoration: none;
          border-bottom: 1px dotted #fff;
        }
      }
      
      .btn {
        font-size: 16px;
        color: #fff;
        text-decoration: none;
        padding: 15px;
        border-radius: 3px;
        margin-right: 10px;
        display: inline-block;
        width: 200px;
      }
      .site {
        background: #3f51b5;
      }
      .hire {
        background: rgba(0, 0, 0, 0.5)
      }
    }
  }


  @media screen and (max-width: 900px){
    .container {
      width: 100%;
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 767px) {
    .container {
      text-align: center;
      left: unset;
      right: unset;
    }
  }

  @media screen and (max-width: 449px) {
    .container {
      left: unset;
      right: unset;
      .hire {
        margin-top: 5px;
      }
    }
  }
`;